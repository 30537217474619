import { React, useEffect, useState } from "react";

import Footer from "./Footer";
import HeaderLower from "./HeaderLower";

import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Helmet } from "react-helmet";

function CertDetails(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Certificate Details";
  const isVoucher = localStorage.getItem("cert_details");

  var cert_details = secureLocalStorage.getItem("cert_details");
  var RedemObject = JSON.parse(cert_details);
  const navigate = useNavigate();


  useEffect(() => {
    if (isVoucher !== "true") {
      navigate("/");
    } else {
      localStorage.setItem("cert_details", false);
    }
  }, []);
  // FIXME

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        {/* <Header /> */}
        {/* <HeaderInner /> */}
        <HeaderLower />
        <div className="white-box">
          <h1>Reward Card Redemption Site</h1>
          <hr />
          <h2>Contact Information</h2>
          <hr />
          <form className="form-outer register clearfix">
            <div className="row">
              <div className="col-md-6">
                <label>
                  <strong>First Name </strong>
                  <br />
                  {RedemObject?.fname ? RedemObject.fname : ""}
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  <strong>Last Name</strong>
                  <br />
                  {RedemObject?.lname ? RedemObject.lname : ""}
                </label>
              </div>
              <div className="clearfix"></div>
              <div className="col-md-6">
                <label>
                  <strong>Address</strong>
                  <br />
                  {RedemObject?.address ? RedemObject.address : ""}
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  <strong>City </strong>
                  <br />
                  {RedemObject?.city ? RedemObject.city : ""}
                </label>
              </div>
              <div className="clearfix"></div>
              <div className="col-md-6">
                <label>
                  <strong>State </strong>
                  <br />
                  {RedemObject?.state ? RedemObject.state : ""}
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  <strong>Zip Code</strong>
                  <br />
                  {RedemObject?.zip ? RedemObject.zip : ""}
                </label>
              </div>
              <div className="clearfix"></div>
              <div className="col-md-6">
                <label>
                  <strong>Phone No </strong>
                  <br />
                  {RedemObject?.phone ? RedemObject.phone : ""}
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  <strong>Email </strong>
                  <br />
                  {RedemObject?.email ? RedemObject.email : ""}
                </label>
              </div>
              <hr />
              <div className="col-md-12">
                <h2>{process.env.REACT_APP_NAME}</h2>
              </div>
              <hr />
              <div className="check-boexs cen">
                <div className="reward_cert">
                  <span className="insdex">
                    {RedemObject?.cardName ? RedemObject.cardName : ""}
                  </span>
                  {RedemObject?.cardImage ? (
                    <img
                      src={
                        process.env.REACT_APP_API_IMAGES + RedemObject.cardImage
                      }
                      alt={RedemObject.cardImage}
                      className="imgcolor img-responsive"
                    />
                  ) : (
                    <div className="pirds pirds2">
                      <span className="rdcrdtop">
                        <table border="0">
                          <tr>
                            <td>
                              {RedemObject?.cardName
                                ? RedemObject.cardName
                                : ""}
                            </td>
                          </tr>
                        </table>
                      </span>
                      <span className="rdcrd">REWARD CARD</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Link to="/" className="btn btn-md btn-primary">
              Go Back
            </Link>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default CertDetails;
