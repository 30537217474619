import axios from "axios";
import { Buffer } from "buffer";
const API_URL = process.env.REACT_APP_API_Link;
const API_ORIGIN = process.env.REACT_APP_API_ORIGIN;
const username = process.env.REACT_APP_API_USERNAME;
const password = process.env.REACT_APP_API_PASSWORD;
const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");

const getStates = () => {
  return axios({
    method: "GET",
    url: API_URL + "getStates",
    headers: {
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};

const getStoreLocation = () => {
  return axios({
    method: "GET",
    url: API_URL + "getStoreLocation",
    headers: {
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};

const getCards = (bodyFormData) => {
  return axios({
    method: "POST",
    url: API_URL + "getCards",
    data: bodyFormData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};

const userForm = (bodyFormData) => {
  return axios({
    method: "POST",
    url: API_URL + "userForm",
    data: bodyFormData,
    headers: {
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};

const UserformService = {
  getStates,
  getCards,
  userForm,
  getStoreLocation,
};

export default UserformService;
