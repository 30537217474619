import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

export default function Error429() {
  const navigate = useNavigate();

  return (
    <div id="notfound">
      <Helmet>
        <title>429 TOO MANY REQUESTS</title>
      </Helmet>
      <div className="notfound">
        <div className="notfound-404">
          <h1>429</h1>
        </div>
        <h2>Too Many Requests!</h2>
        <p>
          You've made too many requests recently. Please wait and try your
          request again later.
        </p>
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          Back To Homepage
        </button>
      </div>
    </div>
  );
}
