import { React, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import "./style.css";
import "./Footer.css";
import "./Info_Section.css";
import "./Input_fields.css";
import "./Button.css";

import HeaderLower from "./HeaderLower";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import StorageService from "../services/storage.service";
import { Helmet } from "react-helmet";

const UserformSurvey = (props) => {
  const [ans1, setAns1] = useState(" ");
  const [ans2, setAns2] = useState(" ");
  const [ans3, setAns3] = useState(" ");
  const [ans4, setAns4] = useState(" ");
  const [ans5, setAns5] = useState(" ");
  const [errors, setErrors] = useState({});
  const [captcha, setCaptcha] = useState(false);
  // const [signoutTime, setSignoutTime] = useState(180000);
  const [signoutTime, setSignoutTime] = useState(900000);

  const TITLE =
    process.env.REACT_APP_API_SITE_TITLE + " | Customer Survey Form";
  const navigate = useNavigate();

  // FIXME
  localStorage.setItem("returnpage", true);
  localStorage.setItem("page", "/survey-form");

  // StorageService.setReturnStatus(true);
  // StorageService.setPage("/UserFormSurvey");

  timeout();
  function timeout() {
    setTimeout(function () {
      logout();
    }, signoutTime);
  }

  const logout = () => {
    destroy();
  };

  const destroy = () => {
    localStorage.setItem("returnpage", true);
    localStorage.removeItem("voucher");
    localStorage.removeItem("page");
    localStorage.removeItem("ans1");
    localStorage.removeItem("ans2");
    localStorage.removeItem("ans3");
    localStorage.removeItem("ans4");
    localStorage.removeItem("ans5");
    window.location.replace("/");
  };
  const handleChangeans1 = (event) => {
    const ans1 = event.target.value;
    setAns1(ans1);
    localStorage.setItem("ans1", ans1);
  };
  const handleChangeans2 = (event) => {
    const ans2 = event.target.value;
    setAns2(ans2);
    localStorage.setItem("ans2", ans2);
  };
  const handleChangeans3 = (event) => {
    const ans3 = event.target.value;
    setAns3(ans3);
    localStorage.setItem("ans3", ans3);
  };
  const handleChangeans4 = (event) => {
    const ans4 = event.target.value;
    setAns4(ans4);
    localStorage.setItem("ans4", ans4);
  };
  const handleChangeans5 = (event) => {
    const ans5 = event.target.value;
    setAns5(ans5);
    localStorage.setItem("ans5", ans5);
    // console.log(ans5);
  };

  function onCaptchaChange(value) {
    if (value === null) {
      setCaptcha(false);
    } else {
      setCaptcha(true);
    }
  }
  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    if (validate()) {
      localStorage.setItem("userformopen", "ture");
      // StorageService.setFormStatus(true);
      if (captcha) {
        secureLocalStorage.setItem("Survey", {
          ans1: ans1,
          ans2: ans2,
          ans3: ans3,
          ans4: ans4,
          ans5: ans5,
        });
        navigate("/user-form");
      }
    } else {
      localStorage.setItem("userformopen", false);
      // StorageService.setFormStatus(false);
    }
  };

  const validate = () => {
    let errors = {};
    let formIsValid = true;

    if (ans1 === " ") {
      formIsValid = false;
      errors["ans1"] = "Please select the option";
      setErrors(errors);
      return false;
    }

    if (ans2 === " ") {
      formIsValid = false;
      errors["ans2"] = "Please select the option";
      setErrors(errors);
      return false;
    }
    if (ans3 === " ") {
      formIsValid = false;
      errors["ans3"] = "Please select the option";
      setErrors(errors);
      return false;
    }
    if (ans4 === " ") {
      formIsValid = false;
      errors["ans4"] = "Please select the option";
      setErrors(errors);
      return false;
    }
    if (ans5 === " ") {
      formIsValid = false;
      errors["ans5"] = "Please enter comment";
      setErrors(errors);
      return false;
    }

    if (!captcha) {
      formIsValid = false;
      errors["checkederrorcaptcha"] = "Captcha not verified";
      setErrors(errors);
      return false;
    }
    return formIsValid;
  };

  const Voucher = localStorage.getItem("voucher");
  const userform = localStorage.getItem("userform");

  // const Voucher = StorageService.getVoucher();
  // const userform = StorageService.getUserformStatus();
  // if (userform === true) {
  //   return <Redirect to="/UserForm" />;
  // }
  useEffect(() => {
    if (userform === "true") {
      navigate("/user-form");
    }

    if (Voucher === undefined || Voucher == null) {
      localStorage.setItem("returnpage", false);
      localStorage.setItem("userform", false);
      localStorage.removeItem("page");
      localStorage.removeItem("ans1");
      localStorage.removeItem("ans2");
      localStorage.removeItem("ans3");
      localStorage.removeItem("ans4");
      localStorage.removeItem("ans5");
      navigate("/");
    }
    if (Voucher === undefined || Voucher == null) {
      StorageService.destroyVoucherInfo1();
      navigate("/");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        {/* <HeaderInner/> */}
        <HeaderLower />
        <div className="white-box form">
          <form
            name="userinfo"
            className="form-outer register clearfix"
            id="userinfo"
            onSubmit={submituserRegistrationForm}
            method="post"
            action=""
          >
            <div className="row">
              <h1 style={{ textAlign: "center" }}>
                {process.env.REACT_APP_SURVEYTITLE}
              </h1>
              <hr />
              <label>
                <b>1</b>. Where did you hear about our offer?<span>*</span>
              </label>

              <label className="inline">
                <input
                  type="radio"
                  id="satisfaction_radio"
                  name="satisfaction_radio"
                  value="TV"
                  onChange={handleChangeans1}
                />{" "}
                TV
              </label>

              <label className="inline ml-2">
                <input
                  type="radio"
                  id="satisfaction_radio"
                  name="satisfaction_radio"
                  value="Pandora"
                  onChange={handleChangeans1}
                />{" "}
                Pandora
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="satisfaction_radio"
                  name="satisfaction_radio"
                  value="Email"
                  onChange={handleChangeans1}
                />{" "}
                Email
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="satisfaction_radio"
                  name="satisfaction_radio"
                  value="Facebook"
                  onChange={handleChangeans1}
                />{" "}
                Facebook
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="satisfaction_radio"
                  name="satisfaction_radio"
                  value="Direct Mail"
                  onChange={handleChangeans1}
                />{" "}
                Direct Mail
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="satisfaction_radio"
                  name="satisfaction_radio"
                  value="Other"
                  onChange={handleChangeans1}
                />{" "}
                Other
              </label>

              <label className="inline ml-2">
                <div className="errorMsg">{errors.ans1}</div>
                <span style={{ color: "red" }} id="satisfaction_msg"></span>
              </label>
              <hr />

              <label>
                <b>2</b>. How easy was it for you to book your appointment?
                <span>*</span>
              </label>
              <label className="inline">
                <input
                  type="radio"
                  id="mattress_radio"
                  name="mattress_radio"
                  value="Very Good"
                  onChange={handleChangeans2}
                />{" "}
                Very Good
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="mattress_radio"
                  name="mattress_radio"
                  value="Good"
                  onChange={handleChangeans2}
                />{" "}
                Good
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="mattress_radio"
                  name="mattress_radio"
                  value="Fair"
                  onChange={handleChangeans2}
                />{" "}
                Fair
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="mattress_radio"
                  name="mattress_radio"
                  value="Poor"
                  onChange={handleChangeans2}
                />{" "}
                Poor
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="mattress_radio"
                  name="mattress_radio"
                  value="Very Poor"
                  onChange={handleChangeans2}
                />{" "}
                Very Poor
              </label>
              <label className="inline ml-2">
                <div className="errorMsg">{errors.ans2}</div>
                <span style={{ color: "red" }} id="mattress_msg"></span>
              </label>
              <hr />
              <label>
                <b>3</b>. How was your experience when you visited our store?
                <span>*</span>
              </label>
              <label className="inline">
                <input
                  type="radio"
                  id="experience_radio"
                  name="experience_radio"
                  value="Very Good"
                  onChange={handleChangeans3}
                />{" "}
                Very Good
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="experience_radio"
                  name="experience_radio"
                  value="Good"
                  onChange={handleChangeans3}
                />{" "}
                Good
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="experience_radio"
                  name="experience_radio"
                  value="Fair"
                  onChange={handleChangeans3}
                />{" "}
                Fair
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="experience_radio"
                  name="experience_radio"
                  value="Poor"
                  onChange={handleChangeans3}
                />{" "}
                Poor
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="experience_radio"
                  name="experience_radio"
                  value="Very Poor"
                  onChange={handleChangeans3}
                />{" "}
                Very Poor
              </label>

              <label className="inline ml-2">
                <div className="errorMsg">{errors.ans3}</div>
                <span style={{ color: "red" }} id="experience_msg"></span>
              </label>

              <hr />

              <label>
                <b>4</b>. How likely are you to book an appointment next time
                you shop at Ashley HomeStore?<span>*</span>
              </label>
              <label className="inline">
                <input
                  type="radio"
                  id="stomach_radio"
                  name="stomach_radio"
                  value="Very Likely"
                  onChange={handleChangeans4}
                />{" "}
                Very Likely
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="stomach_radio"
                  name="stomach_radio"
                  value="Some what Likely"
                  onChange={handleChangeans4}
                />{" "}
                Some what Likely
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="stomach_radio"
                  name="stomach_radio"
                  value="Not Likely"
                  onChange={handleChangeans4}
                />{" "}
                Not Likely
              </label>

              <label className="inline ml-2">
                <div className="errorMsg">{errors.ans4}</div>
                <span style={{ color: "red" }} id="stomach_msg"></span>
              </label>
              <hr />

              <label>
                <b>5</b>. Other Comments<span>*</span>
              </label>
              <textarea
                type="text"
                name=""
                id="store_radio"
                value={ans5}
                className="form-control"
                cols="95"
                tabIndex="5"
                onChange={handleChangeans5}
              ></textarea>
              <label className="inline ml-2">
                <div className="errorMsg">{errors.ans5}</div>
                <span style={{ color: "red" }} id="store_msg"></span>
              </label>

              <br />
              <br />
              <div className="overflow-hider">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                  onChange={onCaptchaChange}
                />
              </div>
              <div className="errorMsg">{errors.checkederrorcaptcha}</div>

              <span style={{ color: "red" }} id="response_msg"></span>
              <br />
            </div>
            <input
              // onClick={() => myTimeout()}
              type="submit"
              className="submit-btn survey"
              name="submit1"
              value="Submit and Move Next"
            />
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default UserformSurvey;
