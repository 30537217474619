import React from "react";
import { useParams } from "react-router-dom";
import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";
import { useState } from "react";
import { useEffect } from "react";
import AdminListService from "../../../services/admin-list.service";

export default function ManageJobDetail() {
  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const { job } = useParams();

  const cardList = async (job) => {
    setLoading(true);
    try {
      const responce = await AdminListService.jobDetail(job);

      if (responce.status === 200) {
        let res;
        if (Array.isArray(responce?.data)) {
          res = responce?.data;
        } else if (typeof responce?.data === "object") {
          res = Object.values(responce?.data);
        }
        const results = [];

        res.map((value) => {
          return results.push({
            card:
              value.card !== ""
                ? value.card.map((item) => {
                    return { name: item };
                  })
                : [{ name: "" }],
            certificates: value.certificates,
            expiration: value.expiration,
            precentage: value.precentage,
            start: value.start,
            total_certificates: value.total_certificates,
            used_certificates: value.used_certificates,
          });
        });

        settableData([...results]);
        setFilteredData([...results]);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);
      setFilteredData([]);
    }
  };

  useEffect(() => {
    if (job) {
      cardList(job);
    }
  }, [job]);

  const Table = ({ data }) => {
    return (
      <div className="table-responsive ">
        <table className="table table-striped table-bordered W-100  ">
          <thead>
            <tr>
              <th width="350" scope="col">
                Job Summary
              </th>
              <th scope="col">Job Cards</th>

              <th scope="col">Certificates</th>
            </tr>
          </thead>
          <tbody>
            {data.length ? (
              data.map((el, index) => (
                <tr key={index}>
                  <td>
                    <table className="inner-job-table">
                      <tbody>
                        <tr>
                          <td>Total Certificate</td>
                          <td>{el.total_certificates}</td>
                        </tr>
                        <tr>
                          <td>Total Used Certificates</td>
                          <td>{el.used_certificates}</td>
                        </tr>
                        <tr>
                          <td>Total Percentage used certificates</td>
                          <td>{el.precentage}</td>
                        </tr>
                        <tr>
                          <td>Begin Redemption</td>
                          <td>{el.start}</td>
                        </tr>
                        <tr>
                          <td>Expiration</td>
                          <td className="text-danger">{el.expiration}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <div className="job-card-multi">
                      {el.card.map((el, index) =>
                        el.name.startsWith("http") ? (
                          <img
                            src={el.name}
                            alt="card logo"
                            className="card-logo-img"
                            key={index}
                          />
                        ) : el.name !== "" ? (
                          <div className="pirds pirds2" key={index}>
                            <span className="rdcrdtop">
                              <table border="0">
                                <tbody>
                                  <tr>
                                    <td>{el.name}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </span>
                            <span className="rdcrd">REWARD CARD</span>
                          </div>
                        ) : (
                          "N/A"
                        )
                      )}
                    </div>
                  </td>

                  <td>
                    <div className="certi-logn">{el.certificates}</div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />

          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Job Details</h5>
            </div>

            <div className="card-body">
              <Table data={filteredData} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
