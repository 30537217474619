import React from "react";
import cityLook from "../Images/city_look.png";
function EmailBox() {
  return (
    <div className="row">
      <div className="col-md-1">
        <img src={cityLook} alt="look.png" width="55" height="57" />
      </div>

      <div className="col-md-11">
        <h2>BE ON THE LOOK OUT!</h2>
        Expect an email within 7-10 business days from{" "}
        <a href={"mailto:" + process.env.REACT_APP_NO_REPLY}>
          {process.env.REACT_APP_NO_REPLY}
        </a>{" "}
        or
        <br />
        <a href={"mailto:" + process.env.REACT_APP_DESC_EMAIL}>
          {process.env.REACT_APP_DESC_EMAIL}
        </a>{" "}
        - we suggest adding these email addresses to your SAFE SENDER list in
        your email settings.
      </div>
    </div>
  );
}

export default EmailBox;
